/* eslint-disable react/no-danger */
import { h } from 'preact';
import { connect } from 'redux-zero/preact';
import { route } from '../../../helpers/router';

import Intl, { translate } from '../../../components/common/intl';
import Button from '../../../components/common/button';

import style from '../style.scss';

const albumButton = (
  <Button primary onClick={() => route('/album')}>
    <Intl textKey="I18N_ALBUM_BUTTON">Album</Intl>
  </Button>
);

const PostEvent = ({ srs }) => (
  <div className={style.container}>
    <h1
      className="textContainer inverted"
      dangerouslySetInnerHTML={{
        __html: translate(
          'I18N_POST_EVENT_TITLE',
          'See you again soon',
        ),
      }}
    />
    <h3
      className="textContainer inverted"
      dangerouslySetInnerHTML={{
        __html: translate(
          'I18N_POST_EVENT_SUBTITLE',
          '&nbsp;',
        ),
      }}
    />
    {Object.keys(srs).length !== 0 && albumButton}
  </div>
);

export default connect(
  ({ srs }) => ({ srs }),
)(PostEvent);
