import { h } from 'preact';
import { connect } from 'redux-zero/react';
import EmailValidator from 'email-validator';

import { useState, useRef } from 'preact/hooks';

import Modal from '../common/modal';
import Button from '../common/button';
import Intl, { translate } from '../common/intl';

import { buildURL, post } from '../../helpers/apis/request';
import { setUserInfo } from './actions';

import style from './style.scss';


const UserInfoModal = ({
  userId,
  slugDate,
  onSuccess,
  onClose,
  setUserInfoAction,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState(false);
  const emailRef = useRef();

  const formSubmitHandler = (event) => {
    event.preventDefault();

    if (!EmailValidator.validate(email)) {
      emailRef.current.setCustomValidity(translate('I18N_CONTACT_INFO_MODAL_INVALID_EMAIL', 'Invalid Email'));
      emailRef.current.reportValidity();
      return;
    }

    setLoading(true);
    const url = buildURL('userContactInfo', userId);
    post(url, {
      firstName,
      lastName,
      email,
      slugDate,
    })
      .then(() => {
        setUserInfoAction({ firstName, lastName, email });
        onClose();
        onSuccess();
      })
      .catch((err) => {
        setError(true);
        console.error('Error while submitting user info', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      className={style.userInfoModal}
      isActive
      header={null}
      footer={null}
      onClose={onClose}
    >
      <div>
        <span
          className={style.header}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: translate(
              'I18N_CONTACT_INFO_MODAL_HEADER',
              'Please provide your name and email to proceed.',
            ),
          }}
        />

        <form className={style.form} onSubmit={formSubmitHandler}>
          <div className={style.formInputWrapper}>
            <div>
              <label htmlFor="firstName">
                <Intl textKey="I18N_CONTACT_INFO_MODAL_FIRST_NAME">
                  First Name
                </Intl>
                :
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  maxLength={50}
                  required
                  placeholder={translate('I18N_CONTACT_INFO_MODAL_FIRST_NAME', 'First Name')}
                  value={firstName}
                  onChange={({ target }) => setFirstName(target.value)}
                  onBlur={({ target }) => setFirstName(target.value.trim())}
                />
              </label>
              <label htmlFor="lastName">
                <Intl textKey="I18N_CONTACT_INFO_MODAL_LAST_NAME">
                  Last Name
                </Intl>
                :
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  maxLength={50}
                  required
                  placeholder={translate('I18N_CONTACT_INFO_MODAL_LAST_NAME', 'Last Name')}
                  value={lastName}
                  onChange={({ target }) => setLastName(target.value)}
                  onBlur={({ target }) => setLastName(target.value.trim())}
                />
              </label>
            </div>
            <label htmlFor="email">
              <Intl textKey="I18N_CONTACT_INFO_MODAL_EMAIL">
                Email
              </Intl>
              :
              <input
                ref={emailRef}
                type="text"
                id="email"
                name="email"
                maxLength={100}
                required
                value={email}
                placeholder={translate('I18N_CONTACT_INFO_MODAL_EMAIL', 'Email')}
                onChange={({ target }) => {
                  setEmail(target.value.trim());
                  target.setCustomValidity('');
                }}
              />
            </label>
          </div>

          {error && (
            <span className={style.errorMessage}>
              <Intl textKey="I18N_CONTACT_INFO_MODAL_SORRY_SOMETHING_WENT_WRONG">Sorry, something went wrong!</Intl>
            </span>
          )}

          <Button
            type="submit"
            primary
            loading={loading}
            disabled={loading || !EmailValidator.validate(email) || !firstName.trim() || !lastName.trim()}
          >
            <Intl textKey="I18N_CONTACT_INFO_MODAL_SUBMIT_BUTTON">
              Continue
            </Intl>
          </Button>
        </form>
      </div>
    </Modal>
  );
};

export default connect(({
  userInfo,
  userId,
  event: { slugDate },
}) => ({
  userInfo,
  userId,
  slugDate,
}), () => ({
  setUserInfoAction: setUserInfo,
}))(UserInfoModal);
